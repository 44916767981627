import { React, forwardRef, Fragment } from 'react';

//komponen
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

//gambar
import PrintIcon from '@mui/icons-material/Print';

function Lap_Hutang_Piutang(props, ref) {
    //fungsi    
    const cetak_pelanggan = () => {
        window.open(props.aplikasi + '/laporan/cetak_hutang_pelanggan.php?permintaan=');
    } 

    const cetak_pelanggan_lunas = () => {
        window.open(props.aplikasi + '/laporan/cetak_hutang_pelanggan.php?permintaan=lunas');
    } 
    
    const cetak_supplier = () => {
        window.open(props.aplikasi + '/laporan/cetak_hutang_supplier.php?permintaan=');
    } 

    const cetak_supplier_lunas = () => {
        window.open(props.aplikasi + '/laporan/cetak_hutang_supplier.php?permintaan=lunas');
    } 

    return (
        <Fragment>
            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Card
                    sx={{ margin: "10px", padding: "10px" }}
                >
                    <Grid container spacing={1}>
                        <Grid container item>
                            <Typography variant="h6" noWrap={true}>Data Hutang Pelanggan</Typography>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3} >

                            </Grid>

                            <Grid item xs={8} sm={8} md={9} sx={{ display: "flex" }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<PrintIcon />}
                                    sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#8080FF" }}
                                    onClick={() => cetak_pelanggan()}
                                >
                                    <Typography variant="body2" noWrap={true}>Cetak</Typography>
                                </Button>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<PrintIcon />}
                                    sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#8080FF" }}
                                    onClick={() => cetak_pelanggan_lunas()}
                                >
                                    <Typography variant="body2" noWrap={true}>Lunas</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>

                <Card
                    sx={{ margin: "10px", padding: "10px" }}
                >
                    <Grid container spacing={1}>
                        <Grid container item>
                            <Typography variant="h6" noWrap={true}>Data Hutang Ke Supplier</Typography>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3} >

                            </Grid>

                            <Grid item xs={8} sm={8} md={9} sx={{ display: "flex" }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<PrintIcon />}
                                    sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#8080FF" }}
                                    onClick={() => cetak_supplier()}
                                >
                                    <Typography variant="body2" noWrap={true}>Cetak</Typography>
                                </Button>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<PrintIcon />}
                                    sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#8080FF" }}
                                    onClick={() => cetak_supplier_lunas()}
                                >
                                    <Typography variant="body2" noWrap={true}>Lunas</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>                
            </Box>
        </Fragment>
    );
}

export default forwardRef(Lap_Hutang_Piutang);