import { React, useState, useEffect, forwardRef } from 'react';

//komponen
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

//gambar
import AddTaskIcon from '@mui/icons-material/AddTask';

function Eom(props, ref) {
    //state
    const [loading_penuh, setLoadingPenuh] = useState(false);
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [proses_terakhir, setProsesTerakhir] = useState("Belum Pernah Diproses");
    const [tampil_dialog_eom, setTampilDialogEom] = useState(false);

    //fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/akuntansi/tampil_proses_terakhir.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setProsesTerakhir(data.data);
                }
                else {
                    setProsesTerakhir("Belum Pernah Diproses");
                }
            })
            .catch(error => {
                setProsesTerakhir("Belum Pernah Diproses");
            }
            );
    }, [props.aplikasi]);

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const proses_eom = () => {
        setTampilDialogEom(false);
        setLoadingPenuh(true);
        fetch(props.aplikasi + '/akuntansi/eom.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_pengguna: localStorage.getItem("id_pengguna")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    let pesan = data.pesan;

                    fetch(props.aplikasi + '/akuntansi/tampil_proses_terakhir.php',
                        {
                            method: 'GET',
                            headers:
                            {
                                "Content-type": "application/json; charset=UTF-8"
                            }
                        })
                        .then(response => response.json())
                        .then(data => {
                            if (data.kode === 1) {
                                setProsesTerakhir(data.data);
                                setJenisNotif("success");
                                setIsiNotif(pesan);
                                setTampilNotif(true);
                                setLoadingPenuh(false);
                            }
                            else {
                                setProsesTerakhir("Belum Pernah Diproses");
                            }
                        })
                        .catch(error => {
                            setProsesTerakhir("Belum Pernah Diproses");
                        }
                        );                    
                }
                else {
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setLoadingPenuh(false);
                }
            })
            .catch(error => {
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
                setLoadingPenuh(false);
            }
            );
    }

    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading_penuh}
            >
                <CircularProgress color="inherit" variant="indeterminate" />

                <Typography variant="h5" style={{ marginTop: "20px", marginLeft: "20px", marginBottom: "20px", color: "white" }}>
                    Proses EOM Sedang Berjalan...
                </Typography>
            </Backdrop>

            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Card
                    sx={{ margin: "10px", padding: "10px" }}
                >
                    <Grid container spacing={1}>
                        <Grid container item>
                            <Grid item xs={12}>
                                <Typography variant="body2">
                                    Proses EOM dilakukan pada bulan berikutnya (setelah ganti bulan)
                                </Typography>

                                <Typography variant="body2" color={"red"}>
                                    Proses terakhir : {proses_terakhir}
                                </Typography>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<AddTaskIcon />}
                                    sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em" }}
                                    onClick={() => setTampilDialogEom(true)}
                                >
                                    <Typography variant="body2" noWrap={true}>Proses Sekarang</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Box>

            <Dialog
                open={tampil_dialog_eom}
                onClose={() => setTampilDialogEom(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Konfirmasi Proses EOM
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Apakah anda yakin ingin melakukan proses EOM?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setTampilDialogEom(false)}
                        autoFocus
                    >
                        Tidak
                    </Button>
                    <Button
                        onClick={() => {
                            proses_eom()
                        }}
                    >
                        Ya
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default forwardRef(Eom);