import { React, useState, useEffect, forwardRef, Fragment } from 'react';

//komponen
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

//gambar
import PrintIcon from '@mui/icons-material/Print';

function Lap_Belanja(props, ref) {
    //state
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [periode1, setPeriode1] = useState(new Date());
    const [periode2, setPeriode2] = useState(new Date());
    const [bulan, setBulan] = useState("");
    const [tahun, setTahun] = useState("");

    //fungsi
    useEffect(() => {
        setBulan((new Date()).getMonth() + 1);
        setTahun((new Date()).getFullYear());
    }, [props.aplikasi]);

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const ubah_tahun = (event) => {
        const re = /^[0-9\b]+$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }
        setTahun(event.target.value);
    }

    const cetak = () => {
        if (periode1 === null) {
            setJenisNotif("warning");
            setIsiNotif("Periode awal wajib diisi");
            setTampilNotif(true);
            return;
        }

        if (periode2 === null) {
            setJenisNotif("warning");
            setIsiNotif("Periode akhir wajib diisi");
            setTampilNotif(true);
            return;
        }

        var tahun = String(periode1.getFullYear());
        var bulan = String(periode1.getMonth() + 1);
        if (bulan.length === 1) {
            bulan = "0" + bulan;
        }
        var tanggal = String(periode1.getDate());
        if (tanggal.length === 1) {
            tanggal = "0" + tanggal;
        }

        var format_periode1 = tahun + "-" + bulan + "-" + tanggal;

        tahun = String(periode2.getFullYear());
        bulan = String(periode2.getMonth() + 1);
        if (bulan.length === 1) {
            bulan = "0" + bulan;
        }
        tanggal = String(periode2.getDate());
        if (tanggal.length === 1) {
            tanggal = "0" + tanggal;
        }

        var format_periode2 = tahun + "-" + bulan + "-" + tanggal;

        window.open(props.aplikasi + '/laporan/cetak_pembelian.php?periode1=' + format_periode1 + '&periode2=' + format_periode2);
    }

    return (
        <Fragment>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Card
                    sx={{ margin: "10px", padding: "10px" }}
                >
                    <Grid container spacing={1}>
                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3} >
                                <Typography variant="body2">
                                    Tanggal Transaksi
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} md={9} sx={{ display: "flex" }}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        inputFormat="dd-MM-yyyy"
                                        value={periode1}
                                        onChange={(newValue) => {
                                            setPeriode1(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>

                                <Typography variant="body2" sx={{ marginLeft: "10px", marginRight: "10px" }}>
                                    s/d
                                </Typography>

                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        inputFormat="dd-MM-yyyy"
                                        value={periode2}
                                        onChange={(newValue) => {
                                            setPeriode2(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3}>

                            </Grid>

                            <Grid item xs={8} sm={8} md={9}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<PrintIcon />}
                                    sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#8080FF" }}
                                    onClick={() => cetak()}
                                >
                                    <Typography variant="body2" noWrap={true}>Cetak</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>                

                {/* <Card
                    sx={{ margin: "10px", padding: "10px" }}
                >                    
                    <Grid container spacing={1}>
                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3}>
                                <Typography variant="body2">
                                    Bulan
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} md={9}>
                                <FormControl size="small" fullWidth>
                                    <Select
                                        id="bulan"
                                        variant="outlined"
                                        value={bulan}
                                        onChange={(event) => setBulan(event.target.value)}
                                    >
                                        <MenuItem value="1">Januari</MenuItem>
                                        <MenuItem value="2">Februari</MenuItem>
                                        <MenuItem value="3">Maret</MenuItem>
                                        <MenuItem value="4">April</MenuItem>
                                        <MenuItem value="5">Mei</MenuItem>
                                        <MenuItem value="6">Juni</MenuItem>
                                        <MenuItem value="7">Juli</MenuItem>
                                        <MenuItem value="8">Agustus</MenuItem>
                                        <MenuItem value="9">September</MenuItem>
                                        <MenuItem value="10">Oktober</MenuItem>
                                        <MenuItem value="11">November</MenuItem>
                                        <MenuItem value="12">Desember</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3}>
                                <Typography variant="body2">
                                    Tahun
                                </Typography>
                            </Grid>

                            <Grid item xs={8} sm={8} md={9}>
                                <TextField
                                    id="tahun"
                                    variant="outlined"
                                    size="small"
                                    value={tahun}
                                    onChange={(e) => ubah_tahun(e)}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={4} sm={4} md={3}>

                            </Grid>                            

                            <Grid container item>
                                <Grid item xs={4} sm={4} md={3}>

                                </Grid>

                                <Grid item xs={8} sm={8} md={9}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<PrintIcon />}
                                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#008222" }}
                                        onClick={() => cetak_rekap()}
                                    >
                                        <Typography variant="body2" noWrap={true}>Rekap</Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card> */}
            </Box>
        </Fragment>
    );
}

export default forwardRef(Lap_Belanja);