import React, { useState, useEffect, forwardRef } from 'react';

//komponen
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

//gambar
import AddOutlined from '@mui/icons-material/AddOutlined';
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import SearchOutlined from '@mui/icons-material/SearchOutlined';

function Toko(props, ref) {
    //state
    const [proses, setProses] = useState(false);
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [tampil_dialog, setTampilDialog] = useState(false);
    const [sumber_toko, setSumberToko] = useState([]);
    const [data_toko, setDataToko] = useState([]);
    const [id_toko, setIdToko] = useState("");
    const [nama, setNama] = useState("");
    const [no_telpon, setNoTelpon] = useState("");
    const [alamat, setAlamat] = useState("");
    const [instagram, setInstagram] = useState("");
    const [status, setStatus] = useState(false);
    const [label_status, setLabelStatus] = useState("Nonaktif");

    //fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/toko/tampil_toko.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        status: ""
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setSumberToko(data.data);
                    setDataToko(data.data);
                }
                else {
                    setSumberToko([]);
                    setDataToko([]);
                }
            })
            .catch(error => {
                setSumberToko([]);
                setDataToko([]);
            }
            );
    }, [props.aplikasi]);

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const tampil_toko = () => {
        fetch(props.aplikasi + '/toko/tampil_toko.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        status: ""
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setSumberToko(data.data);
                    setDataToko(data.data);
                }
                else {
                    setSumberToko([]);
                    setDataToko([]);
                }
            })
            .catch(error => {
                setSumberToko([]);
                setDataToko([]);
            }
            );
    }

    const cari = (event) => {
        var cari = (event.target.value).toLowerCase();
        var awal = 1;
        var akhir = sumber_toko.length;

        var posisi = 0;
        var tampung = [];
        while (awal <= akhir) {
            var hasil = ((sumber_toko[awal - 1]["nama"]).toLowerCase()).indexOf(cari);
            if (hasil === -1) {
                hasil = ((sumber_toko[awal - 1]["nama"]).toLowerCase()).indexOf(cari);
            }

            if (hasil !== -1) {
                tampung[posisi] = sumber_toko[awal - 1];
                posisi++;
            }
            awal++;
        }

        setDataToko(tampung);
    }

    const bersih_input_toko = () => {
        setIdToko("");
        setNama("");
        setNoTelpon("");
        setAlamat("");
        setInstagram("");
        setStatus(false);
        setLabelStatus("Nonaktif");
    }

    const simpan = () => {
        if (nama === "") {
            setJenisNotif("warning");
            setIsiNotif("Nama wajib diisi");
            setTampilNotif(true);
            document.getElementById("nama").focus();
            return;
        }

        if (no_telpon.trim() === "") {
            setJenisNotif("warning");
            setIsiNotif("No. Telpon wajib diisi");
            setTampilNotif(true);
            document.getElementById("no_telpon").focus();
            return;
        }

        if (alamat.trim() === "") {
            setJenisNotif("warning");
            setIsiNotif("Alamat wajib diisi");
            setTampilNotif(true);
            document.getElementById("alamat").focus();
            return;
        }

        setProses(true);
        if (id_toko === "") {
            fetch(props.aplikasi + '/toko/simpan_toko.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            permintaan: "simpan",
                            id_toko: "",
                            nama: nama,
                            no_telpon: no_telpon,
                            alamat: alamat,
                            instagram: instagram,
                            status: status
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        bersih_input_toko();
                        tampil_toko();
                        setJenisNotif("success");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                        setProses(false);
                    }
                    else {
                        tampil_toko();
                        setJenisNotif("warning");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                        setProses(false);
                    }
                })
                .catch(error => {
                    tampil_toko();
                    setJenisNotif("error");
                    setIsiNotif("Terjadi kesalahan");
                    setTampilNotif(true);
                    document.getElementById("nama").focus();
                    setProses(false);
                }
                );
        }
        else {
            fetch(props.aplikasi + '/toko/simpan_toko.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            permintaan: "perbarui",
                            id_toko: id_toko,
                            nama: nama,
                            no_telpon: no_telpon,
                            alamat: alamat,
                            instagram: instagram,
                            status: status
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        tampil_toko();
                        setJenisNotif("success");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                        setProses(false);
                    }
                    else {
                        tampil_toko();
                        setJenisNotif("warning");
                        setIsiNotif(data.pesan);
                        setTampilNotif(true);
                        document.getElementById("nama").focus();
                        setProses(false);
                    }
                })
                .catch(error => {
                    tampil_toko();
                    setJenisNotif("error");
                    setIsiNotif("Terjadi kesalahan");
                    setTampilNotif(true);
                    document.getElementById("nama").focus();
                    setProses(false);
                }
                );
        }
    }

    return (
        <div>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Fab
                    color="primary"
                    sx={{
                        background: "#37ce9b",
                        border: "#37ce9b",
                        margin: 0,
                        top: "auto",
                        right: 20,
                        bottom: 20,
                        left: "auto",
                        position: "fixed",
                        zIndex: "99"
                    }}
                    onClick={() => {
                        bersih_input_toko();
                        setTampilDialog(true);
                    }}
                >
                    <AddOutlined />
                </Fab>

                <Card
                    sx={{ padding: "10px" }}
                >
                    <TextField
                        id="input-with-icon-textfield"
                        label="Cari berdasarkan nama toko"
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchOutlined />
                                </InputAdornment>
                            ),
                        }}
                        onChange={cari}
                    />
                </Card>

                <Grid container spacing={1} sx={{ padding: "10px" }}>
                    {data_toko.map((data_toko) => {
                        return (
                            <Grid
                                item xs={12} sm={6} md={4} lg={3}
                                key={data_toko.id_toko}
                                onClick={() => {
                                    bersih_input_toko();
                                    setIdToko(data_toko.id_toko);
                                    setNama(data_toko.nama);
                                    setNoTelpon(data_toko.no_telpon);
                                    setAlamat(data_toko.alamat);
                                    setInstagram(data_toko.instagram);
                                    if (data_toko.status === "1") {
                                        setStatus(true);
                                        setLabelStatus("Aktif");
                                    }
                                    else {
                                        setStatus(false);
                                        setLabelStatus("Nonaktif");
                                    }
                                    setTampilDialog(true);
                                }}
                            >
                                <Card>
                                    <List>
                                        <ListItem button alignItems="flex-start">
                                            <ListItemText
                                                primary={
                                                    <Typography component={"span"} variant="body1" noWrap>{data_toko.nama}</Typography>
                                                }
                                                secondary={
                                                    <>
                                                        <Typography component={"span"} variant="subtitle1" noWrap>{data_toko.no_telpon}</Typography>
                                                        <br />
                                                        <Typography component={"span"} variant="body2" noWrap>{data_toko.alamat}</Typography>
                                                    </>
                                                }
                                            >
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>

            <Dialog
                open={tampil_dialog}
                onClose={() => setTampilDialog(false)}
            >
                <DialogTitle>Input Data Toko</DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        <Grid container item spacing={1}>
                            <Grid container item
                                sx={id_toko === "" ? {display: "none"} : {}}
                            >
                                <Grid item xs={12} sm={4} md={3}>
                                    <Typography component={"span"} variant="body2">
                                        ID Toko
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={8} md={9}>
                                    <TextField
                                        id="id_toko"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        multiline
                                        InputProps={{
                                            readOnly: true
                                        }}                                        
                                        value={id_toko}
                                        onChange={(event) => setIdToko(event.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={12} sm={4} md={3}>
                                    <Typography component={"span"} variant="body2">
                                        Nama
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={8} md={9}>
                                    <TextField
                                        id="nama"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        multiline
                                        value={nama}
                                        onChange={(event) => setNama(event.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={12} sm={4} md={3}>
                                    <Typography component={"span"} variant="body2">
                                        No. Telpon
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={8} md={9}>
                                    <TextField
                                        id="no_telpon"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        multiline
                                        value={no_telpon}
                                        onChange={(event) => setNoTelpon(event.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={12} sm={4} md={3}>
                                    <Typography component={"span"} variant="body2">
                                        Alamat
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={8} md={9}>
                                    <TextField
                                        id="alamat"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        multiline
                                        value={alamat}
                                        onChange={(event) => setAlamat(event.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={12} sm={4} md={3}>
                                    <Typography component={"span"} variant="body2">
                                        Instagram
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={8} md={9}>
                                    <TextField
                                        id="instagram"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        multiline
                                        value={instagram}
                                        onChange={(event) => setInstagram(event.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            {/* <Grid container item>
                                <Grid item xs={12} sm={4} md={3}>
                                    <Typography component={"span"} variant="body2">
                                        Status
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={8} md={9}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={status}
                                                onChange={(event) => {
                                                    if (status === false) {
                                                        setStatus(true);
                                                        setLabelStatus("Aktif");
                                                    }
                                                    else {
                                                        setStatus(false);
                                                        setLabelStatus("Nonaktif");
                                                    }
                                                }}
                                            />
                                        }

                                        label={label_status}
                                    />
                                </Grid>
                            </Grid> */}
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<SaveOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em" }}
                        loading={proses}
                        onClick={() => simpan()}
                    >
                        <Typography component={"span"} variant="body2" noWrap={true}>{id_toko === "" ? "Simpan" : "Perbarui"}</Typography>
                    </LoadingButton>

                    <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        color="primary"
                        startIcon={<CloseOutlined />}
                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#555555" }}
                        loading={proses}
                        onClick={() => setTampilDialog(false)}
                    >
                        <Typography component={"span"} variant="body2" noWrap={true}>Tutup</Typography>
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default forwardRef(Toko);